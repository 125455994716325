const autoEmail = member => ({
  ...member,
  email:
    member.email
    ?? `${member.name
      .split(' ')[0]
      .toLowerCase()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')}@djavu.cl`,
});
export default [
  {
    name: 'Matías Soffia',
    role: 'Advisor',
    phrase: 'No pain, no gain.',
    pic: 'Matias.jpg',
  },
  {
    name: 'Daniel Aviv',
    role: 'CEO',
    phrase: 'Creo con convicción en el crecimiento personal como motor del crecimiento organizacional',
    pic: 'Daniel.jpg',
  },
  // {
  //   name: 'Fernando Morales',
  //   role: 'CTO',
  //   phrase: '"Cuando vives para los demás, vives para ti mismo." ― Thriss, Nantuko Primus',
  //   pic: 'Nano.jpg',
  // },
  // {
  //   name: 'Jaime Sanz',
  //   role: 'Director',
  //   phrase: '"Si la implementación es difícil de explicar, es una mala idea. Si es fácil de explicar, puede que sea una buena idea." ― Zen de Python',
  //   pic: 'Jimbo.jpeg',
  // },
  {
    name: 'Maximiliano Castro',
    role: 'Project Leader',
    phrase: 'El anhelo de un sueño debe convivir con disfrutar el vértigo del perseguirlo.',
    pic: 'Max.jpg',
    email: 'max@djavu.cl',
  },
  {
    name: 'Nicolás Román',
    role: 'Project Leader',
    phrase:
      '"True magic results from courage of the heart. Boys and girls be ambitious. One step can change the world" ― Ken Akamatsu',
    pic: 'NicolasRman.jpg',
  },
  {
    name: 'Ángel Urbina',
    role: 'Software Engineer',
    phrase: '"One must be sane to think clearly, but one can think deeply and be quite insane." ― Nikola Tesla',
    pic: 'Angel.jpg',
  },
  {
    name: 'Edson Reyes',
    role: 'Software Engineer',
    phrase:
      '"There are 2 types of human beings in the world: those that shout Special Attacks and those that don\'t." — Sorachi Hideaki',
    pic: 'Edson.jpg',
  },
  {
    name: 'Luis Elgueda',
    role: 'HR Department',
    phrase: '"If you can not measure it, you can not improve it." ― W. Thomson',
    pic: 'Luis.jpg',
  },
].map(member => autoEmail(member));
